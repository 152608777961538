
  .required:after {
    content:" *";
    color: red;
  }

  .lining{
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: baseline;
  }

  .font{
    font-family: Arial, Helvetica, sans-serif;
  }

  .content {
  min-height: 91vh;
}

.viewport{
  min-height: 81vh;
}
