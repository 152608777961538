.tokenn {
  max-width: fit-content;
  height: 50px;
  /* background:#ffbd69; */
  text-align: center;
  font-family: cursive;
  font-size: 2.4rem;
  color: rgb(19, 34, 119);
  position: relative;
  /* bottom:45px; */
  /* left:0; */
  /* z-index:99; */
  /* padding-left: 10px; */
  padding-right: 10px;
  padding-top: 5px;
  /* box-shadow:0 10px 15px -7px #212ccae7 */
}
/* .threejs {
    background-color: #193c6d;
    filter: progid: DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#029797', endColorstr='#003073');
    background-image: url(//img.alicdn.com/tps/TB1d.u8MXXXXXXuXFXXXXXXXXXX-1900-790.jpg);
    background-size: 100%;
    background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0, #029797), color-stop(100%, #003073));
    background-image: -webkit-linear-gradient(135deg, #029797, #003073);
    background-image: -moz-linear-gradient(45deg, #029797, #003073);
    background-image: -ms-linear-gradient(45deg, #029797 0, #003073 100%);
    background-image: -o-linear-gradient(45deg, #029797, #003073);
    background-image: linear-gradient(135deg, #029797, #003073);
    text-align: center;
    margin: 0px;
    overflow: hidden;
} */

.threejs {
  background-color: whitesmoke;
}

.dashboard {
  position: absolute;
  top: 60px;
  right: 610px;
}

@media (max-width: 1700px) {
  .dashboard {
    position: absolute;
    top: 60px;
    right: 470px;
  }
}

@media (max-width: 1600px) {
  .dashboard {
    position: absolute;
    top: 60px;
    right: 420px;
  }
}

@media (max-width: 1500px) {
  .dashboard {
    position: absolute;
    top: 60px;
    right: 370px;
  }
}

@media (max-width: 1400px) {
  .dashboard {
    position: absolute;
    top: 60px;
    right: 320px;
  }
}

@media (max-width: 1300px) {
  .dashboard {
    position: absolute;
    top: 60px;
    right: 270px;
  }
}

@media (max-width: 1200px) {
  .dashboard {
    position: absolute;
    top: 60px;
    right: 220px;
  }
}

@media (max-width: 1100px) {
  .dashboard {
    position: absolute;
    top: 60px;
    right: 170px;
  }
}
@media (max-width: 1000px) {
  .dashboard {
    position: absolute;
    top: 60px;
    right: 120px;
  }
}
@media (max-width: 900px) {
  .dashboard {
    position: absolute;
    top: 60px;
    right: 70px;
  }
}

@media (max-width: 800px) {
  .dashboard {
    position: absolute;
    top: 30px;
    right: 40%;
  }
}
